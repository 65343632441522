import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import TextLoop from 'react-text-loop';
import { graphql, StaticQuery } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import Anchor from '../../../../components/Anchor';
import styles from './styles.module.sass';;

export default () => (
  <StaticQuery
    query={graphql`
      query {
        hero: imageSharp(fluid: {
          originalName: {eq: "Dealership-Hero.jpg"}
        }) {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ hero }) => (
      <Grid  item sm={12} md={12}>
        <Grid container justify='center'>
          <Anchor id='main'/>
          <Grid container 
            alignItems='center'
            className={styles.container}>
            <Grid item container xs={12} sm={12} md={12}>
              <Grid item container className={styles.productNameContainer}>
                <Typography
                  variant='h1'
                  weight='light'
                  className={clsx(
                    styles.heroText,
                    styles.title,
                    styles.productName
                  )}>
                  {i18n('Connected car services for car dealerships')}
                </Typography>
              </Grid>
              <Typography
                variant='h2'
                weight='regular'
                align='center'
                className={clsx(
                  styles.pitch,
                  styles.heroText
                )}>
                {i18n('A better way to')}
              </Typography>
              <Typography
                color='secondary'
                variant='h2'
                weight='regular'
                align='center'
                className={clsx(
                  styles.pitchLoop,
                  styles.pitchLoopText,
                  styles.heroText
                )}>
                <TextLoop springConfig={{
                    stiffness: 1000,
                    damping: 40
                  }}>
                  {[
                    i18n('be proactive'),
                    i18n('manage vehicles'),
                    i18n('create value')
                  ]}
                </TextLoop>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={12}
          className={styles.imageContainer}>
          <Img imgStyle={{ objectFit: 'contain'}}
            objectFit='cover'
            className={styles.image}
            fluid={hero.fluid}/>
        </Grid>
      </Grid>
    )}/>
);