import i18n from '../../../locale';
import { dealership } from '../../../data/sitemap';

export default [{
  name: dealership._,
  text: i18n('Dealership'),
  url: i18n('_url:dealership')
}, {
  name: dealership.features,
  text: i18n('Features'),
  url: i18n('_url:dealership-features')
}, {
  name: dealership.price,
  text: i18n('Pricing'),
  url: i18n('_url:dealership-pricing')
}]