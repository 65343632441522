import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import i18n from '../../../../locale';
import SectionWrapper from '../../../../components/SectionWrapper';
import Typography from '../../../../components/Typography';
import Third from './Third';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        third1: imageSharp(fluid: {
          originalName : {eq: "Dealership-Motivation-1.jpg"}
        }) {
          fluid(maxWidth: 1300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        third2: imageSharp(fluid: {
          originalName : {eq: "Dealership-Motivation-2.jpg"}
        }) {
          fluid(maxWidth: 1300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        third3: imageSharp(fluid: {
          originalName : {eq: "Dealership-Motivation-3.jpg"}
        }) {
          fluid(maxWidth: 1300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({
      third1,
      third2,
      third3
    }) => (
      <SectionWrapper className={styles.container}>
        <Typography color='dark' variant='h3' align='left'>
          {i18n('More value for everyone')}
        </Typography>
        <Grid container>
          <Third image={third1}
            containerClass={clsx(
              styles.gridItem,
              styles.gridItemLeftEdge
            )}
            text={i18n('Safer vehicles to drive')}/>
          <Third image={third2}
            containerClass={clsx(
              styles.gridItem,
              styles.gridItemMiddle
            )}
            text={i18n('Convenient planning of new appointments')}/>
          <Third image={third3}
            containerClass={clsx(
              styles.gridItem,
              styles.gridItemRightEdge
            )}
            text={i18n('Take action on time to avoid critical issues')}/>
        </Grid>
      </SectionWrapper>
    )
  }/>
);